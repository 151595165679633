import React from 'react';

import Container from '../components/Container';
import Layout from '../components/Layout/Layout';

import * as styles from './about.module.css';
const AboutPage = (props) => {

  return (
    <Layout>
      <div className={styles.root}>
        <Container size={'large'} spacing={'min'}>
          <div className={styles.content}>
            <h3>About More Than Mainstream</h3>
            <div>
              <p>
                More Than Mainstream is an embodiment of my deepest personal interests, which happen to be some combination of
                technology, philosophy, finance and fitness at the moment.  The idea is to engage in meaningful and open-minded
                conversations with fascinating people, and ultimately to learn.  Mainstream media doesn't always 
                capture some of the questions I find myself asking most, hence the name.
                I hope these conversations are both engaging and insightful, and that you'll join me for the ride.
              </p>
              <img className={styles.founder} alt={'founder'} src={'/about.png'}></img>
            </div>
            <h3>About Me</h3>
            <div>
              <p>
                Hi, I'm Kyle.  I'm a software engineer from Austin, TX, and I also have a background in finance and industrial & systems engineering.
                In my free time I've been trying to improve at pool and tennis, and I'm always trying 
                to make progress in the gym (usually through some combination of powerlifting and olympic lifting).
              </p>
            </div>
          </div>
        </Container>
      </div>
    </Layout>
  );
};

export default AboutPage;
